const hostname = window.location.hostname;
const isLocal = hostname === 'localhost';

const config = {
  apiUrlCodegenClient: isLocal ? 'http://localhost:5004' : `https://${hostname}:5004`,
  apiUrlCodegenServer: isLocal ? 'http://localhost:5001' : `https://${hostname}:5001`,
  apiUrlRest: isLocal ? 'http://localhost:8001' : `https://${hostname}`,
  apiUrlCodegen: isLocal ? 'http://localhost:9001' : `https://${hostname}:9001`,
  enableLabels: false,
};

export default config;

